import React from "react";
import PageTemplate from "./base";
import imgHero from "../../assets/image/industries/spas.jpeg";

const Page = () => {
  return (
    <>
      <PageTemplate
        seoTitle="Free Scheduling Software for Spas | Camelo"
        metaDescription="Help spas operate better, adjust staff shifts for fluctuating situations quickly & simply monitor staff progress with the Camelo app."
        header="The only treatment you need for staff scheduling"
        subHeader="Camelo is a better way to schedule, connect, and manage your team."
        imgUrl={imgHero}
      />
    </>
  );
};

export default Page;
